import React from "react"
import PropTypes from "prop-types"

const Mail = ({ fillColor }) => (
  <svg
    width="19"
    height="15"
    viewBox="0 0 19 15"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M0.678589 0.714111H18.3214C18.3552 0.718127 18.3885 0.724926 18.4212 0.734468L9.50002 7.98025L0.678589 0.714111Z"
      fill={fillColor}
    />
    <path
      d="M9.06842 9.38366C9.31844 9.58839 9.67814 9.58839 9.92815 9.38366L19 2.01303V13.6071C19 13.9819 18.6962 14.2857 18.3214 14.2857H0.678584C0.303806 14.2857 0 13.9819 0 13.6071V1.9126L9.06842 9.38366Z"
      fill={fillColor}
    />
  </svg>
)

export default Mail

Mail.propTypes = {
  fillColor: PropTypes.string,
}

Mail.defaultProps = {
  fillColor: "#1A1C30",
}
