import React from "react"
import PropTypes from "prop-types"
import styled from "styled-components"
import { Mail } from "@bw/icons"
import { useTranslation } from "react-i18next"
import addToMailchimp from "gatsby-plugin-mailchimp"
import { toast } from "react-toastify"
import { breakpoints } from "../../../theme"

const StyledWrapper = styled.div`
  max-width: 100%;
  height: auto;
  overflow: hidden;
`

const StyledHeading = styled.div`
  max-width: 100%;
  height: auto;
  overflow: hidden;

  h3 {
    font-family: var(--font-title);
    font-weight: 900;
    font-size: 16px;
    line-height: 24px;
    margin: 0;

    @media (min-width: ${breakpoints.small}) {
      font-size: 24px;
      line-height: 32px;
    }
  }

  small {
    display: block;
    font-weight: 600;
    font-size: 12px;
    line-height: 20px;
    margin: 0 0 16px;

    @media (min-width: ${breakpoints.small}) {
      font-size: 16px;
      line-height: 24px;
    }
  }
`
const StyledInput = styled.div`
  border: 1.5px solid var(--primary);
  max-width: 300px;
  display: flex;
  flex-direction: row;
  height: 44px;
  box-sizing: border-box;

  &:focus-within {
    border: 1px solid rgba(30, 159, 56, 0.4);
    box-shadow: 0px 0px 12px 4px rgba(30, 159, 56, 0.16);

    input::placeholder {
      color: rgba(30, 159, 56, 0.5);
    }
  }

  button {
    width: 44px;
    padding: 4px;
    background: var(--grey);
    display: flex;
    align-items: center;
    justify-content: center;
    box-sizing: content-box;
    border: none;
  }

  input {
    display: block;
    width: 100%;
    height: 100%;
    padding: 0 10px;
    box-sizing: border-box;
    border: none;
    outline: none;
    font-size: 0.7rem;

    &:focus {
      color: rgba(30, 159, 56, 0.9);
    }

    @media (min-width: ${breakpoints.small}) {
      font-size: 1rem;
      padding: 0 20px;
    }
  }
`

const Newsletter = ({ onSubmit, placeholder, title, subtitle }) => {
  const [email, setEmail] = React.useState("")
  const { t } = useTranslation()

  return (
    <StyledWrapper>
      <StyledHeading>
        <h3>{title}</h3>
        <small>{subtitle}</small>
      </StyledHeading>
      <form
        onSubmit={e => {
          e.preventDefault()
          addToMailchimp(email) // listFields are optional if you are only capturing the email address.
            .then(data => {
              if (data.result === "error") {
                toast.error(t(data.msg))
              }

              if (data.result === "success") {
                toast.success(t(data.msg))
                setEmail("")
              }
            })
        }}
      >
        <StyledInput>
          <button type="submit">
            <Mail />
          </button>
          <input
            type="text"
            value={email}
            onInput={e => {
              setEmail(e.target.value)
            }}
            placeholder={placeholder}
          />
        </StyledInput>
      </form>
    </StyledWrapper>
  )
}

export default Newsletter

Newsletter.propTypes = {
  placeholder: PropTypes.string,
  title: PropTypes.string,
  subtitle: PropTypes.string,
}

Newsletter.defaultProps = {
  placeholder: "Votre e-mail...",
  title: "Inscription Newsletter",
  subtitle: "Ne manquez aucune nouveauté!",
}
