import React from "react"
import PropTypes from "prop-types"
import styled, { css } from "styled-components"
import { Link } from "gatsby"
import { mediaQuery } from "../../../theme.js"

const Teaser = ({
  suptitle,
  title,
  subtitle,
  excerpt,
  to,
  href,
  onClick,
  image,
  imageMode,
}) => {
  console.log(to, href)
  const hasContent = title !== null || excerpt !== null

  const Card = () => (
    <>
      {image}
      {hasContent && (
        <TeaserBody>
          {suptitle && <Small>{suptitle}</Small>}
          {title && <Title>{title}</Title>}
          {subtitle && <Small>{subtitle}</Small>}

          {excerpt && <Excerpt>{excerpt}</Excerpt>}
        </TeaserBody>
      )}
    </>
  )

  switch (true) {
    case to !== null:
      return (
        <StyledLink to={to}>
          <Card />
        </StyledLink>
      )

    case href !== null:
      return (
        <StyledA href={href} target="_blank">
          <Card />
        </StyledA>
      )

    case onClick !== null:
      return (
        <StyledDiv onClick={onClick}>
          <Card />
        </StyledDiv>
      )

    default:
      throw new Error(
        "Unsupported type of teaser. Should use one of these props: to, href or onClick"
      )
  }
}

export default Teaser

Teaser.propTypes = {
  image: PropTypes.element,
  imageMode: PropTypes.oneOf(["cover", "contain"]),
  suptitle: PropTypes.string,
  title: PropTypes.string.isRequired,
  subtitle: PropTypes.string,
  excerpt: PropTypes.string,
  buttonLabel: PropTypes.string,
  onClick: PropTypes.func,
  href: PropTypes.string,
  to: PropTypes.string,
}

Teaser.defaultProps = {
  imageMode: "cover",
}

const TeaserStyle = css`
  text-decoration: none;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.3);
  width: 100%;
  display: grid;
  grid-gap: 5px;
  grid-template-columns: 2fr 3fr;
  color: currentColor;
  cursor: pointer;

  ${mediaQuery("medium")`
  display: block;
  `}
`
const StyledLink = styled(Link)`
  ${TeaserStyle}
`
const StyledA = styled.a`
  ${TeaserStyle}
`
const StyledDiv = styled.div`
  ${TeaserStyle}
`
const TeaserBody = styled.div`
  padding: 5px;

  ${mediaQuery("medium")`
  padding: 15px;
  `}
`

const Small = styled.div`
  text-transform: uppercase;
  letter-spacing: 1px;
  font-size: 10px;
  line-height: 1.2;

  ${mediaQuery("medium")`
  font-size: 12px;
  `}
`

const Title = styled.h3`
  font-size: 15px;
  line-height: 1.1;
  margin: 0.2em 0 0.6em;

  &:first-child {
    margin-top: 0;
  }

  ${mediaQuery("medium")`
  font-size: 24px;
  `}
`

const Excerpt = styled.div`
  display: none;

  ${mediaQuery("medium")`
  display: block;
  margin-top: 0.8em;
  `}
`
